//import vue router
import { createRouter, createWebHistory } from 'vue-router'
import PageNotFound from '@/views/404.vue'
//define a routes
const routes = [
    {
        path: '/',
        name: 'login.index',
        meta: {
            layout: 'login',
        },
        component: () => import(  '@/views/Login.vue')
    },
    {
        path: '/dashboard',
        name: 'dashboard.index',
        meta: {
            layout: 'app',
        },
        component: () => import(  '@/views/dashboard/Index.vue')
    },
    {
        path: '/barang',
        name: 'barang.index',
        meta: {
            layout: 'app',
        },
        component: () => import(  '@/views/barang/Index.vue')
    },
    {
      path: "/barang/riwayat_stok",
      name: "barang.riwayat_stok",
      meta: {
        layout: "app",
      },
      component: () => import("@/views/barang/StockHistory.vue"),
    },
    {
        path: '/profile',
        name: 'profile.index',
        meta: {
            layout: 'app',
        },
        component: () => import(  '@/views/profile/Index.vue')
    },
   
    {
        path: '/penjualan',
        name: 'penjualan.index',
        meta: {
            layout: 'app',
        },
        component: () => import(  '@/views/penjualan/Index.vue')
    },
    {
        path: '/barang_keluar',
        name: 'barang_keluar.index',
        meta: {
            layout: 'app',
        },
        component: () => import(  '@/views/barang_keluar/index.vue')
    },
    {
        path: '/hutang',
        name: 'hutang.index',
        meta: {
            layout: 'app',
        },
        component: () => import(  '@/views/hutang/index.vue')
    },
    {
        path: '/hutang/print',
        name: 'hutang.print',
        meta: {
            layout: 'error',
        }, 
        component: () => import(  '@/views/print/hutang.vue')
    },
    {
        path: '/zdata/data-penjualan-hari-ini',
        name: 'zdata.print',
        meta: {
            layout: 'error',
        }, 
        component: () => import(  '@/views/print/zdata.vue')
    },
    {
        path: '/print',
        name: 'print.index',
        props: true,
        meta: {
            layout: 'error',
        }, 
        component: () => import(  '@/views/print/Index.vue')
    },
    {
        path: '/print-brng-keluar',
        name: 'print.brng.klr.index',
        props: true,
        meta: {
            layout: 'error',
        }, 
        component: () => import(  '@/views/print/brngKeluar.vue')
    },
    {
        path: '/lupapw',
        name: 'lupapw.index',
        props: true,
        meta: {
            layout: 'app',
        }, 
        component: () => import(  '@/views/lupapw/index.vue')
    },
    {   
        path: "/:catchAll(.*)*",  
        meta: {
            layout: 'error',
        }, 
        component : PageNotFound,
     }
]

//create router
const router = createRouter({
    history: createWebHistory(),
    routes,  // config routes
    linkActiveClass: 'active'
})


export default router